import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Layout from '../containers/layout';

import {responsiveTitle1} from '../components/typography.module.css';

export default function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact" />
      <Container>
        <h1 className={responsiveTitle1}>Contact</h1>
        <h3>Board Members</h3>
        <p>
          <b>Priest:</b> Fr. Dragan Goronjic (
          <a href="tel:630-414-7655">630-414-7655</a>,{' '}
          <a href="mailto:goronjic@gmail.com">goronjic@gmail.com</a>)
        </p>
        <p>
          <b>President:</b> Milorad Jovich (
          <a href="tel:330-814-0229">330-814-0229</a>,{' '}
          <a href="mailto:mjovich@aol.com">mjovich@aol.com</a>)
        </p>
        <p>
          <b>1st Vice-President:</b> Miodrag Stankovic
        </p>
        <p>
          <b>2nd Vice-President:</b> Damir Raduka
        </p>
        <p>
          <b>Secretary:</b> Danijela Bozic
        </p>
        <p>
          <b>Treasurer:</b> Mirjana Draganic
        </p>
        <p>
          <b>Financial Secretary:</b> Milanka Marinkovic
        </p>
        <p>
          <b>Kolo Sestara President:</b> Milka Vukelic
        </p>
        <br />
        <p>
          <b>Hall Rental and Catering:</b> Sladja Marinkovic (
          <a href="tel:330-419-3445">330-419-3445</a>)
        </p>

        <br />
        <h3>Auditing Board Members</h3>
        <p>Kathy Geier</p>
        <p>Michael Pancoe</p>
        <p>Ljubodrag (Dragan) Stanic</p>
      </Container>
    </Layout>
  );
}
